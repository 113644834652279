.save-carousel-btn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 15px 32px; /* Padding */
  text-align: center; /* Centered text */
  text-decoration: none;
  display: inline-block;
  font-size: 16px; /* Font size */
  margin: 4px 2px; /* Margin */
  cursor: pointer; /* Cursor as pointer */
  border-radius: 8px; /* Rounded corners */
}