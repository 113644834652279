nav ul li {
  @apply inline pt-2 pb-2 pl-4 pr-4 text-2xl font-light;
}

nav ul li img {
  font-family: 'lato';
  @apply inline align-middle max-h-20;
}

nav ul li a {
  @apply text-inherit decoration-inherit;
}

nav ul li a:hover {
  @apply cursor-pointer underline decoration-[#29859D] text-[#29859D];
}

nav video {
  width: 100%;
  height: 600px;
  object-fit: cover;
  position: absolute; 
  top: 0; 
  left: 0;
  z-index: -1000;
}




#productions {
  background-image: linear-gradient(black, #262222, #262222, #262222);
}

#productions .mtfp {
  @apply text-2xl lg:text-6xl relative top-3.5;
}

#productions .productions {
  @apply text-2xl md:text-4xl lg:text-6xl text-[#CF8B16] lg:p-6 
  max-w-[150px] sm:max-w-[250px] md:max-w-[350px] lg:max-w-[500px]
  min-h-[100px] md:min-h-[125px] lg:min-h-[150px];
  background-image: url("./assets/icons/barreblanc.png");
  background-repeat: no-repeat;
}

#productions .productions span {
  @apply relative top-3.5;
}

#productions .maison-moderne {
  @apply text-xl lg:text-3xl;
}


#quote {
  @apply bg-[#CF8B16] text-center text-white;
}

#quote p {
  @apply m-auto;
}

#quote .big-quote {
  @apply text-3xl md:text-4xl lg:text-6xl font-semibold pt-[40px];
  width: 70%;
}

#quote .small-quote {
  @apply text-base lg:text-lg pt-[40px] pb-[40px];
  width: 40%;
}

.icons {
  padding: 100px 0;
  @apply py-10 m-auto text-center md:py-24;
}

.icons  div {
  @apply m-auto block md:inline-block w-fit md:w-[15%] mb-10 md:mb-0;
}
.icons img {
  @apply text-center m-auto max-h-[80px] md:max-h-[125px];
}



#career {
  background-color: #262222;
  padding-top: 40px;
  padding-bottom: 20px;
  @apply m-auto text-center;
}

#career h2,
#contact h2 {
  @apply text-2xl;
  font-weight: 300;
}

#career h2, 
#career p, 
#career ul {
  color: white;
  padding: 18px;
}

#career p,
#career ul {
  font-size: 18px;
  line-height: 28px;
}

#career ul {
  width: 35%;
  margin: auto;
  text-align: left;
}

#career .email-container {
  width: 50%;
  margin: auto; 
  min-height: 150px;
}

#career .email-container img {
    max-height: 110px;
    @apply m-auto text-center;
}

#career .email-container h2 {
    position: relative;
    top: -100px;
}

#career .email-container a {
    text-decoration: none;
    border: none;
    color: white;
}

#career a:hover {
    text-decoration: underline;
    text-decoration-color: #29859D;
    text-decoration-line: underline;
}

#contact { 
  background-color: #CF8B16;
  min-height: 350px;
  color: white;
  padding-bottom: 40px;
  padding-top: 40px;
}

#contact h1 {
   @apply text-center;
}

#contact h2 {
  @apply text-center;
  padding: 18px;
}

#contact input, #contact textarea {
    width: 80%;
    font-size: 16px;
    min-height: 32px;
    margin-bottom: 30px;
    border-radius: 2px;
    border: 1px #c3c3c3;
    resize: none;
}

#contact #submit {
    font-size: 16px;
    background-color: white;
    color: #CF8B16;
    border: solid 1px white;
    border-radius: 20px;
    width: 200px;
    padding: 8px;
}

#contact #submit:hover {
    cursor: pointer;
    background-color: #F5F5F5;
}
